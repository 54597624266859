import './App.css';
import * as React from 'react';
import { useEffect, useRef } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Hero from './components/Hero';
import AppAppBar from './components/AppAppBar';
import { Box } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const stripePromise = loadStripe('pk_live_51QP7PTDNNLV76OqlIjMRIWKpCkcDNu2bCYSPRon3lZjfBAkSkxo808vlvdSrIKdaG3MjNQvqWLwDHHXSsgMOpdsM00DTrMcXuc');
// const stripePromise = loadStripe('pk_test_51QP7PTDNNLV76OqlQiyrTUOIl4RVskv81TpBTSXxerImEXtUX8EIY5tcED5q19SBgFAmlJlOCyrVHQ1KuW163thc00gmQz0Olu');

function App() {
  const [mode, setMode] = React.useState('light');
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };
  const defaultTheme = createTheme({ palette: { mode } });

  const simpleBarRef = useRef(null);

  useEffect(() => {
    const hash = window.location.hash; // Get the hash from the URL
    if (hash === "#track-order" && simpleBarRef.current) {
      const targetElement = document.getElementById("track-order");
      if (targetElement) {
        // Get SimpleBar scroll container
        const scrollableNode = simpleBarRef.current.getScrollElement();
        // Scroll to the target element within SimpleBar
        const offsetTop = targetElement.offsetTop;
        scrollableNode.scrollTo({ top: offsetTop, behavior: "smooth" });
      }
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline enableColorScheme/>
        <SimpleBar ref={simpleBarRef} style={{ maxHeight: "100vh" }}>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              // backgroundColor: mode === 'light' ? "#d6f49d" : "7c9388",
            }}
          >
            <object
              type="image/svg+xml"
              data={mode === 'light' ? '/QCBackgroundLight.svg' : '/QCBackgroundDark.svg'}
              className="svg-background"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
              }}
            >
              Background image
            </object>
              <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
              <Hero />
          </Box>
        </SimpleBar>
      </ThemeProvider>
    </Elements>
  );
}

export default App;
