import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme,
         useMediaQuery,
         CircularProgress,
         Container,
         Dialog,
         DialogContent,
         DialogTitle,
         DialogActions,
         Divider,
         Box,
         Typography,
         Stack,
         Button,
         TextField,
         InputAdornment,
         Link,
         Select,
         MenuItem,
         InputLabel,
         FormControl,
         FormHelperText,
         Tooltip as TooltipMui, } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSpring, animated } from 'react-spring';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import IconButton from '@mui/material/IconButton';
import PaymentsIcon from '@mui/icons-material/Payments';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PublicIcon from '@mui/icons-material/Public';
import SchemaIcon from '@mui/icons-material/Schema';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import EmailIcon from '@mui/icons-material/Email';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PaymentIcon from '@mui/icons-material/Payment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MouseIcon from '@mui/icons-material/Mouse';
import PersonIcon from '@mui/icons-material/Person';

import BoxReveal from "./magicui/BoxReveal";
import axios from 'axios';
import PriceChart from './PriceChart';
import TransactionsBox from './TransactionsBox';
import VerificationCodeInput from './VerificationCodeInput';
import allStates from './allStates';
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { FaBitcoin } from 'react-icons/fa';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise });
  }

  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    const { stripe } = this.state;
    const accessToken = this.props.accessToken;
    const showAlert = this.props.showAlert;
    const handleSessionExpired = this.props.handleSessionExpired;
    const setAwaitingIdentityVerificationResult = this.props.onWait;
    const setIdentityVerified = this.props.onVerified;

    if (!stripe) {
      // Stripe.js hasn't loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    const response = await fetch('https://app.quickcoin.ai/api/identity/create-verification-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accessToken }),
    });
    const session = await response.json();

    if (!response.ok) {
      if (response.status === 400){
        showAlert(session.err);
      } else if (response.status === 403) {
        showAlert("Session exipred, please log in.")
        handleSessionExpired();
      } else if (response.status === 500) {
        showAlert("Server error. Please try again later.");
      }
    } else {
      const { error } = await stripe.verifyIdentity(session.clientSecret);

      if (error) {
            console.log("Identity verification: network error or user canceled");
          } else {
            setAwaitingIdentityVerificationResult(true);
            const verificationData = await fetch('https://app.quickcoin.ai/api/identity/check-verification-status', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ accessToken }),
            });
            const result = await verificationData.json();
            if (result.identityVerified === "verified") {
              setIdentityVerified(true);
            } else if (result.identityVerified) {
              showAlert(`${result.identityVerified}, please try again`);
            } else {
              showAlert(result.err);
            }
            setAwaitingIdentityVerificationResult(false);
          }

    }
  };

  render() {
    const { stripe } = this.state;
    return (
      <Button
        variant="contained"
        onClick={this.handleClick}
        sx={{
          width: "25%",
          mt: '1rem',
          mb: '0.5rem',
          fontSize: '0.9rem',
          backgroundColor: this.props.theme === 'light' ? '#605B5B' : '#495E5F',
          color: alpha('#f4f4f4', 0.8),
          borderRadius: '8px',
          px: '1.2rem',
          py: '0.3rem',
          '&:hover': {
            backgroundColor: this.props.theme === 'light' ? '#c4e87d' : '#4B6855',
          },
        }}
        disabled={!stripe}
      >
        Verify
      </Button>
    );
  }
}

export default function Hero() {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [latestPrice, setLatestPrice] = useState(null);
  const [termsOpen, setTermsOpen] = useState(false);
  const [formExpanded, setFormExpanded] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [identityVerified, setIdentityVerified] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [awaitingEmailVerification, setAwaitingEmailVerification] = useState(false);
  const [awaitingEmailVerificationAPIResponse, setAwaitingEmailVerificationAPIResponse] = useState(false);
  const [cryptoAddressBinded, setCryptoAddressBinded] = useState(false);
  const [awaitingIdentityVerificationResult, setAwaitingIdentityVerificationResult] = useState(false);
  const [sendingEmailVerificationRequest, setSendingEmailVerificationRequest] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isResendClickable, setIsResendClickable] = useState(true);
  const [resendSecondsLeft, setResendSecondsLeft] = useState(0);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [trackedOrderNumber, setTrackedOrderNumber] = useState('');
  const [trackedOrderDetail, setTrackedOrderDetail] = useState({});
  // const [trackingOrder, setTrackingOrder] = useState(false);
  const [trackingOpen, setTrackingOpen] = useState(false);
  const xs = useMediaQuery('(max-width:600px)');
  const md = useMediaQuery('(max-width:1050px)');

  const CARD_ELEMENT_OPTIONS = (theme) => ({
    style: {
      base: {
        color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Text color
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
      },
      invalid: {
        color: '#fa755a', // Invalid input color
        iconColor: '#fa755a', // Invalid icon color
      },
    },
    paymentMethodConfiguration: {
      payment_method_types: ['card'],
      card: {
        allowedCardNetworks: ['visa', 'mastercard', 'amex', 'discover'],
        allowedCardTypes: ['debit', 'prepaid']
      }
    }
    // hidePostalCode: true, // Optional: Matches zip code field without a postal code field
  });

  useEffect(() => {
    const fetchBitcoinData = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
          params: {
            vs_currency: 'usd',
            days: '30',
          },
        });

        const prices = response.data.prices.map((price) => ({
          x: new Date(price[0]),
          y: price[1],
        }));

        const latestPrice = prices[prices.length - 1]?.y || null;

        setLatestPrice(latestPrice);
      } catch (error) {
        console.error('Error fetching Bitcoin data:', error);
      }
    };

    fetchBitcoinData();
  }, []);

  const formik = useFormik({
    initialValues: {
      usdAmount: '',
      btcAmount: '',
      phoneNumber: '',
      email: '',
      bitcoinAddress: '',
      fullName: '',
      addressLine1: '',
      addressLine2: '',
      USState: '',
    },
    validationSchema: Yup.object({
      usdAmount: Yup.number().nullable().required('Required'),
      btcAmount: Yup.number().nullable().required('Required'),
      phoneNumber: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      bitcoinAddress: Yup.string().required('Required'),
      fullName: Yup.string().required('Required'),
      addressLine1: Yup.string().required('Required'),
      addressLine2: Yup.string(),
      USState: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
        handleConfirmPaymentClick();
    },
  });

  const handleTermsOpen = () => setTermsOpen(true);
  const handleTermsClose = () => setTermsOpen(false);

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  }

  const handleUsdChange = (event) => {
    let usdValue = event.target.value;

    // Filter out non-numeric characters except for the decimal point
    usdValue = usdValue.replace(/[^0-9.]/g, '');

    // Allow leading zero only if it's followed by a decimal point
    if (usdValue.startsWith('0') && !usdValue.startsWith('0.')) {
      usdValue = usdValue.replace(/^0+(?=\d)/, '');
    }

    // Prevent multiple decimal points
    if ((usdValue.match(/\./g) || []).length > 1) {
      return;
    }

    // Restrict to 2 decimal places
    if (usdValue.includes('.')) {
      const [integerPart, decimalPart] = usdValue.split('.');
      usdValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
    }

    // Update btcAmount only when there's a valid input
    if (usdValue && !isNaN(usdValue)) {
      const btcValue = parseFloat(usdValue) / latestPrice;
      formik.setFieldValue('btcAmount', btcValue.toFixed(8));
    } else {
      formik.setFieldValue('btcAmount', '');
    }

    formik.setFieldValue('usdAmount', usdValue);
  };

  const handleBtcChange = (event) => {
    let btcValue = event.target.value;

    // Filter out non-numeric characters except for the decimal point
    btcValue = btcValue.replace(/[^0-9.]/g, '');

    // Allow leading zero only if it's followed by a decimal point
    if (btcValue.startsWith('0') && !btcValue.startsWith('0.')) {
      btcValue = btcValue.replace(/^0+(?=\d)/, '');
    }

    // Prevent multiple decimal points
    if ((btcValue.match(/\./g) || []).length > 1) {
      return;
    }

    // Restrict to 8 decimal places
    if (btcValue.includes('.')) {
      const [integerPart, decimalPart] = btcValue.split('.');
      btcValue = `${integerPart}.${decimalPart.substring(0, 8)}`;
    }

    // Update usdAmount only when there's a valid input
    if (btcValue && !isNaN(btcValue)) {
      const usdValue = parseFloat(btcValue) * latestPrice;
      formik.setFieldValue('usdAmount', usdValue.toFixed(2));
    } else {
      formik.setFieldValue('usdAmount', '');
    }

    formik.setFieldValue('btcAmount', btcValue);
  };

  const handlePhoneNumberChange = (event) => {
    let phoneNumber = event.target.value;

    // Filter out non-numeric characters
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

    // Limit the phone number to 10 digits
    phoneNumber = phoneNumber.slice(0, 10);

    formik.setFieldValue('phoneNumber', phoneNumber);
  };

  const handleBuyNowClick = () => {
    setShowAddressForm(false);
    setFormExpanded(true);
  };

  const handleGoBackClick = () => {
    if (showAddressForm) {
      setShowAddressForm(false);
      if (!cryptoAddressBinded) {
        formik.setFieldValue('bitcoinAddress', '');
      }
      formik.setFieldValue('phoneNumber', '');

      formik.setTouched({
        bitcoinAddress: false,
        phoneNumber:false,
      }, false);
    } else if (showPaymentForm){
      setShowPaymentForm(false);
      setShowAddressForm(true);
      formik.setFieldValue('fullName', '');
      formik.setFieldValue('addressLine1', '');
      formik.setFieldValue('addressLine2', '');
      formik.setFieldValue('USState', '');

      formik.setTouched({
        fullName: false,
        addressLine1:false,
      }, false);

    } else {
      setFormExpanded(false);
      formik.setFieldValue('email', '');
      setAwaitingEmailVerification(false);
      setVerificationCode('');

      formik.setTouched({
        email: false,
      }, false);
    }
  };

  const handleRequestVerificationCode = async () => {
    const emailAddress = formik.values.email;

    setSendingEmailVerificationRequest(true);

    //Make API call to send the verification code
    await fetch('https://app.quickcoin.ai/api/email-verification/send-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ emailAddress }),
    }).then(response => {
        if (response.ok) {
          setAwaitingEmailVerification(true);
        } else {
          response.text().then(err => showAlertMessage(`Error: ${err}`));
        }
      }).catch(error => {
        showAlertMessage(`Error sending verification code: ${error}`);
      });

    setSendingEmailVerificationRequest(false);
  };

  const handleResendClick = () => {
    if (isResendClickable) {
      handleRequestVerificationCode();
      setIsResendClickable(false);
      setResendSecondsLeft(60);
    }
  };

  useEffect(() => {
    let timer;
    if (resendSecondsLeft > 0) {
      timer = setInterval(() => {
        setResendSecondsLeft((prev) => prev - 1);
      }, 1000);
    } else if (resendSecondsLeft === 0) {
      setIsResendClickable(true); // Re-enable click after countdown
    }
    return () => clearInterval(timer); // Cleanup timer
  }, [resendSecondsLeft]);

  const handleVerificationCodeChange = (code) => {
    setVerificationCode(code);
  };

  const handleCodeVerification = async () => {
    const emailAddress = formik.values.email;

    setAwaitingEmailVerificationAPIResponse(true);

    try {
      // Make API call to send the verification code
      const response = await fetch('https://app.quickcoin.ai/api/email-verification/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailAddress, code: verificationCode }),
      })

      if (response.ok) {
        const result = await response.json();
        setAccessToken(result.accessToken);
        setIdentityVerified(result.identityVerified);
        if (result.cryptoAddress){
          formik.setFieldValue('bitcoinAddress', result.cryptoAddress);
          setCryptoAddressBinded(true);
        }
      } else {
          const error = await response.text();
          showAlertMessage(error);
      }
    } catch (error) {
        showAlertMessage(`Error verifying code: ${error}`);
    } finally {
      setAwaitingEmailVerificationAPIResponse(false);
    }
  };

  const handleSessionExpired = () => {
    setTimeout(() => window.location.reload(), 2000);
  }

  const handleBitcoinAddressChange = (event) => {
    let btcAddress = event.target.value;

    // Filter out non-numeric characters
    btcAddress = btcAddress.replace(/[^a-zA-Z0-9]/g, '');

    // Limit the btc address to 42 digits
    btcAddress = btcAddress.slice(0, 42);

    formik.setFieldValue('bitcoinAddress', btcAddress);

  };

  const handleLegalNameChange = (event) => {
    const input = event.target.value;

    // Allow only letters, spaces, hyphens, and apostrophes
    const filteredInput = input
      .replace(/[^a-zA-Z-' ]/g, '') // Remove invalid characters
      .replace(/\s{2,}/g, ' ') // Replace multiple spaces with a single space
      .trimStart(); // Remove leading spaces

    // Update Formik value
    formik.setFieldValue('fullName', filteredInput);
  };

  const handleAddressLineChange = (event) => {
    const input = event.target.value;

    // Allow valid characters: letters, numbers, spaces, commas, periods, hyphens, slashes, apostrophes
    const filteredInput = input
      .replace(/[^a-zA-Z0-9,.\-/ ']/g, '') // Remove invalid characters
      .replace(/\s{2,}/g, ' ') // Replace multiple spaces with a single space
      .trimStart(); // Remove leading spaces

    // Update Formik value
    formik.setFieldValue(event.target.name, filteredInput); // Use the 'name' prop for generic reuse
  };

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
  };

  const handleSubmitPayment = () => {
    handleConfirmPaymentClick();
  };

  const createPaymentIntent = async () => {
      try {
          const response = await fetch('https://app.quickcoin.ai/api/payment/create-payment-intent', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                accessToken: accessToken,
                amount: formik.values.usdAmount,
                btcAddress: formik.values.bitcoinAddress,
                fullName: formik.values.fullName,
                addressLine1: formik.values.addressLine1,
                addressLine2: formik.values.addressLine2,
                USState: formik.values.USState,
              }),
          });

          const data = await response.json();
          if (!response.ok) {
            if (response.status === 400){
              return {err: data.err};
            } else if (response.status === 401) {
              return {err: "Payment blocked possibly due to the following reasons: 1. Restrictions posed by state regulations. 2. We miss licenses to operate in your state. 3. Risk control flagged the transaction. 4. Payment processor declined the request. 5. Internal server error."};
            } else if (response.status === 403) {
              handleSessionExpired();
              return {err: "Session exipred, please log in."};
            } else if (response.status === 500) {
              return {err: data.err};
            }
          } else {
            return {
              clientSecret: data.clientSecret,
              orderNumber: data.orderNumber,
            }
          }
      } catch (err) {
          return {err};
      }
  };

  const handleConfirmPaymentClick = async () => {
      try {

        setLoading(true);
        if (!stripe || !elements) {
            console.error('Stripe.js has not loaded.');
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: formik.values.fullName,
                phone: formik.values.phoneNumber,
                address: {
                  line1: formik.values.addressLine1,  // Street address
                  line2: formik.values.addressLine2,
                  state: formik.values.USState,  // State
                },
            },
        });

        if (paymentMethodError) {
            showAlertMessage('Error creating Payment Method: '+ paymentMethodError.message);
            setLoading(false);
            return;
        }

        // console.log(paymentMethod.card.funding);

        if (paymentMethod.card.funding !== "debit" && paymentMethod.card.funding !== 'prepaid') {
          showAlertMessage('Only debit card is accepted.');
          setLoading(false);
          return;
        }

        const { clientSecret, orderNumber, err } = await createPaymentIntent();

        if (err) {
            showAlertMessage('Error creating Payment Session: ' + err);
            setLoading(false);
            return;
        }
        const {paymentIntent, error} = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id
        }) ;

        if (error) {
          console.log(JSON.stringify(error, null, 2));
          showAlertMessage(`Payment failed or requires further action: ${error}`);
          setLoading(false);
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
          setOrderSubmitted(true);
          setOrderNumber(orderNumber);
          setLoading(false);
        } else {
          showAlertMessage(`Payment failed`);
          setLoading(false);
        }

      } catch (err) {
          showAlertMessage('Error:' + err);
          setLoading(false);
          return;
      }
  };

  const handleOrderSubmittedGoBack = () => {
    setOrderSubmitted(false);
    setFormExpanded(false);
    setShowAddressForm(false);
    setShowPaymentForm(false);
    const btcAddress = formik.values.bitcoinAddress;
    formik.resetForm();
    formik.setFieldValue('bitcoinAddress', btcAddress)
    setCryptoAddressBinded(true);
  };

  const handleTrackedNumberChange = (event) => {
    const input = event.target.value;
    setTrackedOrderNumber(input);
  };

  const handleTrackOrder = async (e) => {

    if (!trackedOrderNumber) {
      showAlertMessage('Please enter a valid order number!')
      return
    }
    setTrackingOpen(true);
    // setTrackingOrder(true);

    try {

      const response = await fetch('https://app.quickcoin.ai/api/util/track-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderNumber: trackedOrderNumber }),
      })

      if (response.ok) {
          const orderDetail = await response.json();
          setTrackedOrderDetail(orderDetail.order);
      } else {
          response.text().then(err => showAlertMessage(`Error: ${err}`));
      }
    } catch(error) {
        showAlertMessage(`Error tracking order: ${error}`);
    };

    // setTrackingOrder(false);
  };

  const formSpring = useSpring({
    opacity: formExpanded ? 1 : 0,
    transform: formExpanded ? 'translateY(0)' : 'translateY(20px)',
    config: { tension: 280, friction: 60 },
  });

  const chartSpring = useSpring({
    opacity: formExpanded ? 0 : 1,
    transform: formExpanded ? 'translateY(-20px)' : 'translateY(0)',
    config: { tension: 280, friction: 60 },
  });

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        height: '100%',
        px: 2,
      }}
    >
      <Container
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: 'center',
          backgroundColor: theme.palette.mode === 'light'? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)',
          mt: { xs: 10, sm: 16 },
          mb: { xs: 10, sm: 30 },
          pb: { xs: 3, sm: 4 },
          px: { xs: 2, sm: 5 },
          pt: { xs: 2, sm: 3 },
          borderRadius: 5,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          transition: theme.transitions.create(
            ['background-color'],
            {
              duration: theme.transitions.duration.standard,
            }
          ),
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            py: { xs: 5, sm: 10 },
            pl: '10%',
            pr: { xs: '10%', sm: '20%', md: '40%' },
            my: { xs: 2, sm: 3 },
            borderRadius: 12,
            backgroundColor: theme.palette.mode === 'light'? '#716969' : '#161616',
          }}
        >
          <Box>
            <BoxReveal boxColor={"#d6f49d"} duration={0.5}>
              <Typography variant="h1" fontWeight="fontWeightBold" sx={{ fontSize: { xs: '4rem' }, color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                Quick Coin<span style={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}>.</span>
              </Typography>
            </BoxReveal>

            <BoxReveal boxColor="#d6f49d" duration={0.5}>
              <Typography variant="h6" sx={{ mt: '0.5rem', ml: '0.5rem', fontSize: '2rem', color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                Easy access to{' '}
                <Typography component="span" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F', fontSize: '2rem' }}>
                  Crypto-currencies
                </Typography>
              </Typography>
            </BoxReveal>

            <BoxReveal boxColor="#d6f49d" duration={0.5}>
              <Box sx={{ mt: '0.5rem', ml: '0.5rem' }}>
                <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                  <EastIcon sx={{ verticalAlign: 'middle' }} /> 20+ coins and 48+ US states with
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> fast transaction </Typography>
                  and
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> low fees</Typography>.
                  <br />
                  <EastIcon sx={{ verticalAlign: 'middle' }} /> <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> Transparent </Typography> reviews,
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> trackble </Typography> orders,
                  and
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> top secuirty </Typography>measures.
                  <br />
                </Typography>
              </Box>
            </BoxReveal>

            <BoxReveal boxColor={"#d6f49d"} duration={0.5} >
              <Button
                sx={{
                  mt: '1.6rem',
                  ml: '0.5rem',
                  backgroundColor: theme.palette.mode === 'light'? '#605B5B' : '#495E5F',
                  color: alpha('#f4f4f4',0.8),
                  borderRadius: '8px',
                  px: '1.2rem',
                  py: '0.3rem',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'light'? '#d6f49d' : '#4B6855',
                  },
                }}
              >
                Start
              </Button>
            </BoxReveal>
          </Box>
        </Box>
        <Stack spacing={2} useFlexGap sx={{ width: "100%" }}>
          {!orderSubmitted ? (
            <Box sx={{height: "100%", display: 'flex', flexDirection: 'row', mt: 5}}>
              {(formExpanded && !md) && (
                <Timeline position="alternate">
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      align="right"
                      variant="body2"
                      color="text.secondary"
                    >
                      Step 1
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <FingerprintIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2, color: theme.palette.mode === 'light'? (showAddressForm || showPaymentForm)? '#605b5b' : 'inherit' : (showAddressForm || showPaymentForm)? '#909090' : 'inherit', transition: 'color 0.3s ease-in-out', }}>
                      <Typography variant="h6" component="span">
                        Identity
                      </Typography>
                      <Typography>Help us know who you are</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Step 2
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <PaymentIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2, color: theme.palette.mode === 'light'? showAddressForm? 'inherit' : '#605b5b' : showAddressForm? 'inherit' : '#909090', transition: 'color 0.3s ease-in-out' }}>
                      <Typography variant="h6" component="span">
                        Wallet
                      </Typography>
                      <Typography>Sending the cryptos to your wallet</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                  <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Step 3
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <HowToRegIcon />
                      </TimelineDot>
                      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2, color: theme.palette.mode === 'light'? showPaymentForm? 'inherit' : '#605b5b' : showPaymentForm? 'inherit' : '#909090', transition: 'color 0.3s ease-in-out' }}>
                      <Typography variant="h6" component="span">
                        Payment
                      </Typography>
                      <Typography>Enter your payment method</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                      <TimelineDot color="secondary">
                        <MouseIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                      <Typography variant="h6" component="span">
                        Submit
                      </Typography>
                      <Typography>The final step</Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              )}
              <Box id="function-box" sx={{width: "100%", height: formExpanded? {sm: "532px"} : "100%"}}>
                <animated.div style={chartSpring}>
                  {!formExpanded && (
                    <Stack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        border: '3px solid',
                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#212121',
                        borderRadius: 5,
                        p: 2,
                        gap: 2,
                        mt: { xs: 0, sm: 4 },
                      }}
                    >
                      <TextField
                        hiddenLabel
                        size="medium"
                        variant="standard"
                        value={formik.values.usdAmount}
                        onChange={handleUsdChange}
                        placeholder="Amount in USD"
                        InputProps={{
                          startAdornment: <InputAdornment position="start" >
                              <Box sx={{ fontSize: '2rem', display: 'flex', alignItems: 'center', px: 0.75, color: theme.palette.mode === 'light' ? '#605b5b' : '#909090'}}>
                              $
                              </Box>
                            </InputAdornment>,
                          autoComplete: 'off',
                          sx: {
                            border: 'none',
                            borderBottom: '1px solid',
                            fontSize: {xs:'1.75rem', sm: '1.5rem', md:'1.75rem'},
                            color: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          style: { textAlign: 'right' },
                        }}
                        sx={{
                          width: { xs: '90%', sm: '300px' },
                          '& .MuiInput-underline:before': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                          },
                        }}
                        error={formik.touched.usdAmount && Boolean(formik.errors.usdAmount)}
                      />
                      <TextField
                        hiddenLabel
                        size="medium"
                        variant="standard"
                        value={formik.values.btcAmount}
                        onChange={handleBtcChange}
                        placeholder="Amount in BTC"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FaBitcoin style={{ fontSize: '2rem', color: theme.palette.mode === 'light' ? '#605b5b' : '#909090' }}/>
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                          sx: {
                            border: 'none',
                            borderBottom: '1px solid',
                            fontSize: {xs:'1.75rem', sm: '1.5rem', md:'1.75rem'},
                            color: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          style: { textAlign: 'right' },
                        }}
                        sx={{
                          width: { xs: '90%', sm: '300px' },
                          '& .MuiInput-underline:before': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                          },
                        }}
                        error={formik.touched.btcAmount && Boolean(formik.errors.btcAmount)}
                      />
                      <Button
                        sx={{
                          my: '1.5rem',
                          ml: '1.5rem',
                          backgroundColor: theme.palette.mode === 'light'? '#605B5B' : '#495E5F',
                          color: alpha('#f4f4f4',0.8),
                          borderRadius: 4,
                          fontSize: '1.25rem',
                          px: '1.5rem',
                          py: '0.75rem',
                          '&:hover': {
                            backgroundColor: theme.palette.mode === 'light'? '#d6f49d' : '#4B6855',
                          },
                        }}
                        variant="contained"
                        onClick={handleBuyNowClick}
                        disabled={!formik.values.usdAmount || !formik.values.btcAmount}
                      >
                        Buy Now
                      </Button>
                    </Box>
                    <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, mt: 2, fontSize: '1rem', color: '#605B5B'}}>
                        By clicking &quot;Buy Now&quot; you agree to our&nbsp;
                        <Link color={theme.palette.mode === 'light'? "#7C9388" : '#4B6855'} onClick={handleTermsOpen}
                          sx={{
                            '&:hover': {
                              textDecoration: 'underline', // Adds underline on hover
                              color: theme.palette.mode === 'light' ? '#5A7D6A' : '#3C5648', // Adjust hover color
                              cursor: 'pointer', // Ensures the cursor is a pointer
                            },
                          }}
                        >
                          Terms & Conditions
                        </Link>
                        .
                    </Typography>
                    </Stack>
                  )}
                </animated.div>
                <animated.div style={{...formSpring, height: '100%'}}>
                  {formExpanded && (
                    <Box
                      id="verification-box"
                      sx={{
                        width: '100%',
                        height: "100%",
                        p: {xs:2, md:4},
                        border: '3px solid',
                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                        borderRadius: 5,
                        transition: 'all 0.3s ease-in-out',
                      }}
                    >
                      <IconButton onClick={handleGoBackClick} sx={{ alignSelf: 'flex-start', mb: 2 }}>
                        <ArrowBackIcon sx={{color: theme.palette.mode === 'light' ? '#716969' : '#909090'}}/>
                      </IconButton>
                      <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2}>
                          {!(showAddressForm || showPaymentForm) && (
                            <>
                              {accessToken? (
                                <>
                                  {identityVerified? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <HowToRegIcon sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', fontSize: {xs: '4rem', sm: '5rem'}, mb: 2, mt: {sm: 3}}}/>
                                      <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1.5rem', fontWeight: 'bold'}}>
                                        Identity Verified
                                      </Typography>
                                      <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1rem'}}>
                                        Verfication results are valid for 180 days
                                      </Typography>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          width: {xs: "50%", sm:"50%", md:"30%", lg:"25%"},
                                          mt: '1.6rem',
                                          mb: '0.5rem',
                                          backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : '#495E5F',
                                          color: alpha('#f4f4f4', 0.8),
                                          borderRadius: '8px',
                                          px: '1.2rem',
                                          py: '0.3rem',
                                          fontSize: '0.9rem',
                                          '&:hover': {
                                            backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                                          },
                                        }}
                                        onClick={() => setShowAddressForm(true)}
                                      >
                                        Next Step
                                      </Button>
                                    </Box>
                                  ):(
                                      <>
                                      {awaitingIdentityVerificationResult? (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                            <CircularProgress thickness={6} size="5rem" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', mb: 3, mt:{sm: 10, md:10, lg:8}}} />
                                            <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, mb:{xs: 4}, fontSize: '1rem'}}>
                                                This usually takes 1-2 minutes
                                            </Typography>
                                        </Box>
                                      ):(
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <PersonIcon sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', fontSize: {xs: '4rem', sm: '5rem'}, mb: 2, mt: {sm: 3}}}/>
                                          <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1.5rem', fontWeight: 'bold'}}>
                                            Identity Verification
                                          </Typography>
                                          <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1rem'}}>
                                            This is required by the regulations
                                          </Typography>
                                          <VerifyButton
                                            stripePromise={stripe}
                                            theme={theme.palette.mode}
                                            accessToken={accessToken}
                                            showAlert={showAlertMessage}
                                            handleSessionExpired={handleSessionExpired}
                                            onWait={setAwaitingIdentityVerificationResult}
                                            onVerified={setIdentityVerified}
                                          />
                                        </Box>
                                      )}
                                      </>
                                    )}
                                </>
                              ):(
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <EmailIcon sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', fontSize: {xs: '4rem', sm: '5rem'}, mb: 2, mt: {sm: 3}}}/>
                                  <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1.5rem', fontWeight: 'bold'}}>
                                    Email Verification
                                  </Typography>
                                  {!awaitingEmailVerification? (
                                    <>
                                      <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1rem'}}>
                                        Sign up or log in using your email address
                                      </Typography>
                                      <TextField
                                        label="Email Address"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        sx={{
                                          mt: 2,
                                          width: {xs:"90%", sm: "75%", lg:"60%"},
                                          '& .MuiInputBase-input': {
                                            color: theme.palette.mode === 'light' ? '#605b5b' : '#f1f1f1', // Input text color
                                          },
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              border: '3px solid',
                                              borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                              borderRadius: 5,
                                            },
                                            '&:hover fieldset': {
                                              borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#7c9388', // Focus state color
                                            },
                                            '& input': {
                                              backgroundColor: 'transparent',
                                            },
                                            '& input:-webkit-autofill': {
                                              backgroundColor: 'transparent !important',
                                              WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                              transition: 'background-color 5000s ease-in-out 0s',
                                            },
                                            '& input:-internal-autofill-selected': {
                                              backgroundColor: 'transparent !important',
                                            },
                                          },
                                          '& .MuiInputLabel-root': {
                                            color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                          },
                                          '& .MuiInputLabel-root.Mui-focused': {
                                            color: theme.palette.mode === 'light' ? '#7c9388' : '#4B6855',
                                          },
                                        }}
                                        {...formik.getFieldProps('email')}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                      />
                                      <Button
                                        variant="contained"
                                        sx={{
                                          width: {xs:"90%", sm: "75%", lg:"60%"},
                                          mt: '1.6rem',
                                          mb: '0.5rem',
                                          backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : '#495E5F',
                                          color: alpha('#f4f4f4', 0.8),
                                          borderRadius: '8px',
                                          px: '1.2rem',
                                          py: '0.3rem',
                                          fontSize: '0.9rem',
                                          '&:hover': {
                                            backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                                          },
                                        }}
                                        onClick={handleRequestVerificationCode}
                                        disabled={
                                          !formik.values.email ||
                                          Boolean(formik.errors.email) ||
                                          sendingEmailVerificationRequest
                                        }
                                      >
                                        {sendingEmailVerificationRequest? <CircularProgress size='1.6rem' color={alpha('#f4f4f4', 0.8)}/>: "Send Code"}
                                      </Button>
                                    </>) : (
                                      <>
                                        <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1rem'}}>
                                          Enter the 6-digit code
                                        </Typography>
                                        <VerificationCodeInput onCodeChange={handleVerificationCodeChange}/>
                                        <Button
                                          variant="contained"
                                          onClick={handleCodeVerification}
                                          sx={{
                                            width: {xs:"90%", sm: "75%", lg:"60%"},
                                            mt: '1.6rem',
                                            mb: '0.5rem',
                                            fontSize: '0.9rem',
                                            backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : '#495E5F',
                                            color: alpha('#f4f4f4', 0.8),
                                            borderRadius: '8px',
                                            px: '1.2rem',
                                            py: '0.3rem',
                                            '&:hover': {
                                              backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                                            },
                                          }}
                                          disabled={verificationCode.length !== 6}
                                        >
                                          {awaitingEmailVerificationAPIResponse? <CircularProgress size='1.6rem' color={alpha('#f4f4f4', 0.8)}/>: "Verify Code"}
                                        </Button>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                          }}
                                        >
                                        <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '0.9rem'}}>
                                          Didn't receive the code?
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          onClick={handleResendClick}
                                          sx={{
                                            color: theme.palette.mode === 'light' ? '#d6f49d' : '#495E5F',
                                            pl: 1,
                                            fontSize: '0.9rem',
                                            cursor: isResendClickable ? 'pointer' : 'default', // Make it look clickable
                                            '&:hover': {
                                              textDecoration: isResendClickable ? 'underline' : 'none', // Optional hover effect
                                            },
                                          }}
                                        >
                                          {isResendClickable
                                            ? "Resend"
                                            : `Try again in ${resendSecondsLeft}s`}
                                        </Typography>
                                        </Box>
                                      </>
                                  )}
                                </Box>
                              )}
                            </>
                          )}
                          {showAddressForm && (
                            <Box
                              sx={{
                                display: 'flex',
                                height: "100%",
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <AccountBalanceIcon sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', fontSize: {xs: '4rem', sm: '5rem'}, mb: 0, mt: {sm: 3}}}/>
                              <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, fontSize: '1.5rem', fontWeight: 'bold'}}>
                                Current processing time: 48h
                              </Typography>
                              <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, mb: 1, fontSize: '1rem'}}>
                                Trial run, processing could take longer than usual
                              </Typography>
                              <TooltipMui title={cryptoAddressBinded ? "For fraud prevention purposes, the btc address is bound for 180 days." : ""} placement="top">
                                <TextField
                                  label="Bitcoin Wallet Address"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  value={formik.values.bitcoinAddress}
                                  onChange={handleBitcoinAddressChange}
                                  disabled={cryptoAddressBinded}
                                  sx={{
                                    width: {xs:"90%", sm: "75%", lg:"60%"},
                                    mb: 1.5,
                                    mt: 1,
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: '3px solid',
                                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                        borderRadius: 5,
                                      },
                                      '&:hover fieldset': {
                                        borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#7c9388', // Focus state color
                                      },
                                      '& input': {
                                        backgroundColor: 'transparent',
                                      },
                                      '& input:-webkit-autofill': {
                                        backgroundColor: 'transparent !important',
                                        WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                        transition: 'background-color 5000s ease-in-out 0s',
                                      },
                                      '& input:-internal-autofill-selected': {
                                        backgroundColor: 'transparent !important',
                                      },
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: '#7c9388', // Focused label color
                                    },
                                  }}
                                  error={formik.touched.bitcoinAddress && Boolean(formik.errors.bitcoinAddress)}
                                />
                              </TooltipMui>
                              <TextField
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={formik.values.phoneNumber}
                                onChange={handlePhoneNumberChange}
                                slotProps={{
                                  input: {
                                    startAdornment:
                                    <InputAdornment position="start">
                                      <Box sx={{ display: 'flex', alignItems: 'center', px: 0.75, color: theme.palette.mode === 'light' ? '#605b5b' : '#909090'}}>
                                      +1
                                      </Box>
                                    </InputAdornment>,
                                  },
                                }}
                                sx={{
                                  width: {xs:"90%", sm: "75%", lg:"60%"},
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388',
                                  },
                                }}
                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                              />
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setShowAddressForm(false);
                                  setShowPaymentForm(true);
                                }}
                                sx={{
                                  width: {xs:"90%", sm: "75%", lg:"60%"},
                                  mt: '1.6rem',
                                  backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : "#495E5F",
                                  color: '#f4f4f4',
                                  borderRadius: '8px',
                                  px: '1.2rem',
                                  py: '0.3rem',
                                  '&:hover': {
                                    backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : "#4B6855",
                                  },
                                }}
                                disabled={
                                  !formik.values.bitcoinAddress ||
                                  formik.values.phoneNumber.length <= 9
                                }
                              >
                                Next step
                              </Button>
                            </Box>
                          )}
                          {showPaymentForm && (
                            <Box>
                              <Typography variant="body1" sx={{fontSize: "18px", color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, mb:1}}>
                                Billing address:
                              </Typography>
                              <TextField
                                label="Full name"
                                variant="outlined"
                                fullWidth
                                required
                                value={formik.values.fullName}
                                onChange={handleLegalNameChange}
                                sx={{
                                  mb:1.5,
                                  ml:0.5,
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388', // Focused label color
                                  },
                                }}
                                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                              />
                              <TextField
                                label="Address line 1"
                                variant="outlined"
                                name="addressLine1"
                                value={formik.values.addressLine1}
                                onChange={handleAddressLineChange}
                                fullWidth
                                required
                                sx={{
                                  width: "100%",
                                  mb:1.5,
                                  ml:0.5,
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388',
                                  },
                                }}
                                error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                              />
                              <Box
                                sx={{
                                  mx: 0.5,
                                  width: "100%",
                                  display: 'flex',
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  label="Address line 2"
                                  variant="outlined"
                                  name="addressLine2"
                                  fullWidth
                                  value={formik.values.addressLine2}
                                  onChange={handleAddressLineChange}
                                  sx={{
                                    mb:1,
                                    mr: 0.5,
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: '3px solid',
                                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                        borderRadius: 5,
                                      },
                                      '&:hover fieldset': {
                                        borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#7c9388', // Focus state color
                                      },
                                      '& input': {
                                        backgroundColor: 'transparent',
                                      },
                                      '& input:-webkit-autofill': {
                                        backgroundColor: 'transparent !important',
                                        WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                        transition: 'background-color 5000s ease-in-out 0s',
                                      },
                                      '& input:-internal-autofill-selected': {
                                        backgroundColor: 'transparent !important',
                                      },
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: '#7c9388',
                                    },
                                  }}
                                  error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                                />
                                <FormControl
                                  fullWidth
                                  required
                                  error={formik.touched.USState && Boolean(formik.errors.USState)}
                                  sx={{
                                    mb: 1,
                                    ml: 0.5,
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: '3px solid',
                                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                        borderRadius: 5,
                                      },
                                      '&:hover fieldset': {
                                        borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#7c9388', // Focus state color
                                      },
                                      '& select': {
                                        backgroundColor: 'transparent',
                                      },
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: '#7c9388',
                                    },
                                  }}
                                >
                                  <InputLabel id="state-select-label">State</InputLabel>
                                  <Select
                                    label="State"
                                    id="state-select"
                                    {...formik.getFieldProps('USState')}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200, // Maximum height of the menu in pixels
                                          overflow: 'auto', // Enables scrolling when content exceeds height
                                          borderRadius: 8, // Rounded corners for the menu
                                          backgroundColor: theme.palette.mode === 'light' ? '#f5f5f5' : '#424242', // Match background color
                                        },
                                        sx: {
                                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Optional: Add a shadow for a better effect
                                          '&::-webkit-scrollbar': {
                                            width: '8px', // Width of the scrollbar
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#b0b0b0', // Color of the scrollbar thumb
                                            borderRadius: '4px', // Roundness of the scrollbar thumb
                                          },
                                          '&::-webkit-scrollbar-thumb:hover': {
                                            backgroundColor: '#909090', // Hover color for the scrollbar thumb
                                          },
                                          '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#f0f0f0', // Background color of the scrollbar track
                                          },
                                          scrollbarWidth: 'thin', // For Firefox: Makes the scrollbar thin
                                          scrollbarColor: '#b0b0b0 #f0f0f0', // For Firefox: thumb and track color
                                        },
                                      },
                                    }}
                                  >
                                    {allStates.map((state) => (
                                      <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                        {state.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {formik.touched.USState && formik.errors.USState && (
                                    <FormHelperText>{formik.errors.USState}</FormHelperText>
                                  )}
                                </FormControl>
                              </Box>
                              <Typography variant="body1" sx={{fontSize: "18px", color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1, mb:0.5}}>
                                Debit Card information:
                              </Typography>
                              <Box
                                sx={{
                                  ml:0.5,
                                  mr:-0.5,
                                  pt:2,
                                  px:2,
                                  border: '3px solid',
                                  borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                  borderRadius: 5, // Match border radius
                                  backgroundColor: 'transparent',
                                  '::placeholder': {
                                    color: theme.palette.mode === 'light' ? '#a1a1a1' : '#c1c1c1', // Placeholder color
                                  },
                                }}
                              >
                                <CardElement options={CARD_ELEMENT_OPTIONS(theme)} onChange={handleCardChange}/>
                                <Box sx={{ textAlign: 'center', my: 1, display:"flex", justifyContent: "center", alignItems: "center"}}>
                                  <Typography variant="body1" textAlign="center" sx={{ fontSize: "13px", color: '#666666', pl:0.5, my: 1}}>
                                    Your information is stored and protected by
                                  </Typography>
                                  <img
                                    src="/stripeLogo.png" // Path to your logo in the public folder
                                    alt="Stripe Logo"
                                    style={{ width: '50px', height: 'auto' }} // Adjust as needed
                                  />
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  ml:0.5,
                                  mr:-0.5,
                                  mt: 3,
                                }}
                              >
                                <Typography variant="body1" sx={{width: "18%", fontSize: "16px", color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:0.5}}>
                                  {"Service charge: "}
                                </Typography>
                                <Typography variant="body1" sx={{width: "20%", fontSize: "16px", color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', fontWeight: 'bold'}}>
                                    4.25% + 0.3
                                  </Typography>
                                <Button
                                  variant="contained"
                                  sx={{
                                    width: "62%",
                                    backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : "#495E5F",
                                    color: '#f4f4f4',
                                    borderRadius: '8px',
                                    px: '1.2rem',
                                    py: '0.3rem',
                                    '&:hover': {
                                      backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : "#4B6855",
                                    },
                                  }}
                                  onClick={handleSubmitPayment}
                                  disabled={
                                    !formik.values.fullName ||
                                    !stripe||
                                    !formik.values.addressLine1||
                                    !formik.values.USState ||
                                    !isCardComplete ||
                                    loading
                                  }
                                >
                                  {loading ? <CircularProgress size='1.6rem' color={alpha('#f4f4f4', 0.8)}/> : `Confirm Payment: $${Number(formik.values.usdAmount*1.0425+0.3).toFixed(2)}`}
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Stack>
                      </form>
                    </Box>
                  )}
                </animated.div>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                px: 2,
                mt: 3,
                textAlign: 'center',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <LocalAtmIcon sx={{width:'2.5rem', height:'2.5rem', color: '#716969' }}/>
              <Typography variant="h3" sx={{ mb: 3, color: '#716969' }} >
                SUCCESS!
              </Typography>
              <Typography variant="body1" sx={{ mb: 1, color: '#716969' }}>
                Your order has been submitted.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: '#716969' }}>
                An email notification will be sent to {formik.values.email} shortly.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: 3,
                  px: 8,
                  mb: 3,
                  border: '2px solid',
                  borderColor: '#716969',
                  borderRadius: 2,
                  textAlign: 'center',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <Typography sx={{ color: '#716969' }} >
                  Order Number: #{orderNumber}
                </Typography>
                <Typography sx={{ color: '#716969' }} >
                  Status: processing
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{
                  ml: '0.5rem',
                  backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : "#495E5F",
                  color: '#f4f4f4',
                  borderRadius: '8px',
                  px: '1.2rem',
                  py: '0.3rem',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : "#4B6855",
                  },
                }}
                onClick={handleOrderSubmittedGoBack}>
                Back to home
              </Button>
            </Box>
          )}
        </Stack>
        {!formExpanded && (
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              mt: 4,
            }}
          >

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' }, // Column on small screens, row on medium and larger screens
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' }, // Stack the child boxes on small screens, row on medium and up
                  width: '100%',
                  gap: { xs: 2, md: 5 }, // Smaller gap on small screens
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    borderColor: theme.palette.mode === 'light' ? '#716969' : '#212121',
                    borderWidth: '3px',
                    borderStyle: 'solid',
                    borderRadius: '20px',
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                    maxWidth: '537px',
                    mb: { xs: 2, lg: 0 }, // Add bottom margin on small screens to create spacing
                  }}
                >
                  <TransactionsBox />
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    boxSizing: 'border-box',
                    width: '100%',
                    maxWidth: '537px',
                  }}
                >
                  <PriceChart />
                </Box>
              </Box>
            </Box>


            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: theme.palette.mode === 'light'? '#605B5B' : '#909090',
                width: '100%',
                mt: 12,
              }}
            >
              Why Us?
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                px: 2,
                py: 4,
                mt: 5,
              }}
            >
              {/* Part 1 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <PaymentsIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' } }}>
                    Instant Payout
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' }, }}>
                    {xs? 'Get your crypto instantly' : 'Get your crypto instantly if approved and paid with debit card'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 2 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <SupportAgentIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    24/7 Support
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'Our support team is ready 24 by 7' : 'Our support team is alway accessible and ready to help 24 by 7'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 3 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <PublicIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    Nationwide access
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'Purchase cryptos accross US' : 'Purchase cryptos from 48 states accross the United States'}
                  </Typography>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                px: 2,
                py: 4,
              }}
            >
              {/* Part 1 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <SchemaIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' } }}>
                    Effortless Checkouts
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'Simple purchasing process' : 'Simple purchasing process, offering easy and smooth experience'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 2 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <VerifiedUserIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    Top Privacy
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'We collect only necessary information' : 'We collect the minimum information required by regulations'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 3 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <AssuredWorkloadIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    Secured Payments
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'We process your payment safely' : 'We process your payment via secured gateways'}
                  </Typography>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderColor: theme.palette.mode === 'light'? '#605b5b' : '#909090',
                borderWidth: '3px',
                borderStyle: 'solid',
                borderRadius: '20px',
                boxSizing: 'border-box',
                width: '100%',
                mt: 10,
              }}
            >
              <Box component="img"
                src={`/FinCEN.svg`}
                sx={{ width: 100, height: 100, my: 2, mr: {xs: 1, md: 2}, ml: {xs:'10%', md:'20%'} }}
              />
              <Box
                sx={{
                  flexDirection: 'column',
                  alignContent: 'center',
                  ml: 4,
                  p: 2,
                  pr: 4,
                }}
              >
                <Typography variant="h6" fontSize={'1.25rem'} sx={{ color: theme.palette.mode === 'light'? '#716969' : '#909090' }}>
                    {xs? 'FinCEN Department of the Treasury' : 'FinCEN Department of the Treasury, United States of America'}
                </Typography>
                <Typography variant="body1" sx={{ color: '#666' }}>
                    {xs? 'MSB Registration: 31000280819427' : 'Money Service Business Registration: 31000280819427'}
                </Typography>
              </Box>
            </Box>

          </Stack>
        )}
            <Box
              id="track-order"
              display= "flex"
              flexDirection= {{xs:'column', md:'row'}}
              sx={{
                backgroundColor: {xs: theme.palette.mode === 'light'? '#716969' : '#161616', md:'rgb(255,255,255,0)'},
                backgroundImage: theme.palette.mode === 'light'? 'url(/QCFooter.svg)' : 'url(/QCFooterDark.svg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '20px',
                width: '100%',
                pb: 4,
                px: 3,
                mt: 10,
              }}
            >
              <Box
                display= "flex"
                flexDirection= 'row'
              >
                <Box
                  display= "flex"
                  flexDirection= "column"
                  alignItems="flex-start"
                  marginTop={{xs:0, md:10}}
                  marginLeft={{xs:3, md:5, lg:8}}
                  marginRight={{xs:2, md:4, lg:6}}
                >
                  <Typography sx={{mt:8, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                    Contacts
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    team@quickcoin.ai
                  </Typography>
                  <Typography sx={{mt: 5, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                    Address
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    QuickCoin LLC
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    Wyoming, US
                  </Typography>
                  {/* Add more components like buttons or links inside the Stack if needed */}
                </Box>

                <Box
                  display= "flex"
                  flexDirection= "column"
                  alignItems="flex-start"
                  marginTop={{xs:0, md:10}}
                  marginLeft={{xs:3, md:5, lg:8}}
                  marginRight={{xs:2, md:4, lg:6}}
                >
                  <Typography sx={{mt:8, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                    Information
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    About Us
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    News
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    FAQs
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    Privacy Policy
                  </Typography>
                  {/* Add more components like buttons or links inside the Stack if needed */}
                </Box>
              </Box>
              <Box
                display= "flex"
                flexDirection= "column"
                alignItems="flex-start"
                marginTop={{sm:0, md:10}}
                marginLeft={{xs:3, md:5, lg:8}}
              >
                <Typography sx={{mt:{xs:2, md:8}, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                  Social Media
                </Typography>
                <Box sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090', display: 'flex', flexDirection: 'row'}}>
                  <XIcon sx={{height: '2rem', width: '2rem', mt: 1}}/>
                  <InstagramIcon sx={{height: '2rem', width: '2rem', mt: 1}}/>
                  <LinkedInIcon sx={{height: '2rem', width: '2rem', mt: 1}}/>
                </Box>
                <Typography sx={{mt: 2, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                  Track Order
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: {xs:'100%', sm:'60%', md:'75%', lg:'100%'},
                    backgroundColor: theme.palette.mode === 'light' ? '#716969' : '#161616',
                  }}
                >
                  <TextField
                    hiddenLabel
                    size="medium"
                    variant="standard"
                    value={trackedOrderNumber}
                    onChange={handleTrackedNumberChange}
                    placeholder="Order number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ fontSize: '1.5rem', display: 'flex', alignItems: 'center', px: 0.75, color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                            #
                          </Box>
                        </InputAdornment>
                      ),
                      autoComplete: 'off',
                      sx: {
                        border: 'none',
                        borderBottom: '1px solid',
                        fontSize: '1.5rem',
                        color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090',
                      },
                      style: { textAlign: 'right' },
                    }}
                    sx={{
                      width: { xs: '90%', sm: '300px' },
                      '& .MuiInput-underline:before': {
                        borderBottomColor: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: theme.palette.mode === 'light'? '#c4e87d' : '#4B6855',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090',
                      },
                    }}
                  />
                  <Button
                    onClick={handleTrackOrder}
                    sx={{
                      ml: '1rem',
                      backgroundColor: theme.palette.mode === 'light'? '#605b5b' : '#495E5F',
                      color: alpha('#f4f4f4',0.8),
                      borderRadius: 2,
                      fontSize: '1rem',
                      mt: '0.4rem',
                      px: '0.8rem',
                      py: '0.3rem',
                      '&:hover': {
                        backgroundColor: theme.palette.mode === 'light'? '#c4e87d' : '#4B6855',
                      },
                    }}
                    variant="contained"
                  >
                    Track
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', color: '#716969', mt: 5, pl: 1 }}>
              <Typography sx={{fontSize:'0.9rem'}}>
                © 2024 QuickCoin.com, all rights reserved.
              </Typography>
            </Box>
      </Container>

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        PaperProps={{
          sx: {
            borderRadius: 4,
            backgroundColor: theme.palette.mode === 'light'? '#f4f4f4' : "#161616",
          },
        }}
      >
        <DialogTitle sx={{ color: theme.palette.mode === 'light'? '#716969' : "#909090" }}>An error has occurred</DialogTitle>
        <DialogContent sx={{ color: theme.palette.mode === 'light'? '#716969' : "#909090" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography>{alertMessage}</Typography>
            </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={trackingOpen}
        onClose={() => setTrackingOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 8,
            backgroundColor: theme.palette.mode === 'light' ? '#f4f4f4' : '#1c1c1c',
            padding: 4,
            maxWidth: 500,
            width: '90%',
          },
        }}
      >
        {/* Header Section */}
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: 'bold',
            color: theme.palette.mode === 'light'? '#716969' : "#909090",
            padding: 2,
          }}
        >
          Order Summary
        </DialogTitle>

        {/* Content Section */}
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 2,
            color: theme.palette.mode === 'light'? '#716969' : "#909090",
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0',
              borderBottom: `1px solid ${
                theme.palette.mode === 'light' ? '#f0f0f0' : '#2c2c2c'
              }`,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Recipient
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {trackedOrderDetail.btcAddress}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0',
              borderBottom: `1px solid ${
                theme.palette.mode === 'light' ? '#f0f0f0' : '#2c2c2c'
              }`,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              BTC Amount
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {trackedOrderDetail.btcAmount}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0',
              borderBottom: `1px solid ${
                theme.palette.mode === 'light' ? '#f0f0f0' : '#2c2c2c'
              }`,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              USD Amount
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              ${trackedOrderDetail.usdAmount}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0',
              borderBottom: `1px solid ${
                theme.palette.mode === 'light' ? '#f0f0f0' : '#2c2c2c'
              }`,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Order Time
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {new Date(trackedOrderDetail.orderTime).toLocaleString()}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0',
              borderBottom: `1px solid ${
                theme.palette.mode === 'light' ? '#f0f0f0' : '#2c2c2c'
              }`,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Paid
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {trackedOrderDetail.paid ? 'Yes' : 'No'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Fulfilled
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {trackedOrderDetail.fulfilled ? 'Yes' : 'No'}
            </Typography>
          </Box>
        </DialogContent>

        {/* Footer Section */}
        <Box
          sx={{
            px: 1,
            textAlign: 'center',
          }}
        >
          <Button
            sx={{
              width: "100%",
              mt: 1,
              mb: 2,
              backgroundColor: theme.palette.mode === 'light'? '#605b5b' : '#495E5F',
              color: alpha('#f4f4f4',0.8),
              borderRadius: 2,
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'light'? '#c4e87d' : '#4B6855',
              },
            }}
            variant="contained"
            onClick={() => setTrackingOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={termsOpen}
        onClose={handleTermsClose}
        scroll='paper'
        fullWidth={true}
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "500px",
          '& .MuiDialog-paper': {
            maxHeight: '80vh', // Limit dialog height to 80% of the viewport height
            borderRadius: 4,
            backgroundColor: theme.palette.mode === 'light'? '#f4f4f4' : "#000000",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            textTransform: "uppercase",
            fontSize: "25px",
            color: "#333333",
            position: "relative",
            mb: "8px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              mb: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DescriptionIcon
              sx={{
                fontSize: "56px",
                mt: "40px",
                mb: "8px",
                color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              }}
            />
            <Box
              sx={{
                width: "90%",
                px: "16px",
              }}
            >
              <Divider
                textAlign="center"
                sx={{
                  color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
                  fontWeight: "bold",
                  "&::before, &::after": {
                    borderColor: theme.palette.mode === 'light' ? "#d3d3d3" : '#495E5F',
                    borderWidth: "3px",
                  },
                }}
              >
                Terms & Conditions
              </Divider>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Center child elements horizontally
              alignItems: "center", // Optional: Center vertically
              width: "100%",
            }}
          >
            <Typography tabIndex={-1}
              sx={{
                width: "90%",
                mb:"16px",
                color: "#666",
                textTransform: "none",
                fontSize: "13px",
              }}
            >
              These Terms and Conditions ("Terms") govern your use of the services provided by QuickCoin, LLC ("Company," "we," "us," or "our"). By accessing or utilizing our website and services, you ("User" or "you") acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you must refrain from using our services.
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            mx:"4%",
          }}
        >
          <SimpleBar style={{ maxHeight: "500px" }}>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Services
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          QuickCoin, LLC offers retail services for the purchase of cryptocurrency by individuals in exchange for fiat currency. Our services enable Users to purchase cryptocurrency directly through our website, facilitated via the RocketFuel API. QuickCoin, LLC acts solely as an intermediary for the sale of cryptocurrency and does not hold, manage, or provide custodial services for cryptocurrency on behalf of its Users.
          </Typography>

          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Know Your Customer (KYC) and Anti-Money Laundering (AML) Compliance
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          As part of our compliance with the Bank Secrecy Act (BSA), the Financial Crimes Enforcement Network (FinCEN) regulations, and other applicable Anti-Money Laundering (AML) laws, QuickCoin, LLC is required to verify the identity of its Users ("KYC").<br/><br/>
          •	User Verification: You agree to provide accurate, complete, and up-to-date personal information, including but not limited to your full name, government-issued identification (such as a passport, driver’s license, or other photo ID), proof of address, and any other documentation we may request for the purposes of identity verification.<br/><br/>
          •	Purpose: This information is collected for the purpose of preventing, detecting, and reporting money laundering, terrorist financing, and other financial crimes as required by federal and state laws.<br/><br/>
          •	Failure to Provide Information: If you fail to provide the required identification documents or refuse to comply with our KYC/AML procedures, QuickCoin, LLC reserves the right to refuse, suspend, or terminate your access to our services, at our sole discretion.<br/><br/>
          •	Ongoing Monitoring: You acknowledge that we may conduct ongoing monitoring of your account and transactions to ensure compliance with FinCEN regulations, and may request updated information or documentation at any time.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Acknowledgment of Risks
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          By utilizing our services, you expressly acknowledge and agree that the purchase and sale of cryptocurrency are inherently risky and speculative. You further acknowledge and accept the following risks:<br/><br/>
          •	Volatility: The value of cryptocurrency is highly volatile, and prices may fluctuate significantly within short periods. We do not guarantee the value or future performance of any cryptocurrency.<br/><br/>
          •	Security: You are solely responsible for safeguarding any private keys, account information, or other authentication credentials. The Company bears no liability for any loss, theft, or unauthorized access to your account resulting from your failure to protect such credentials.<br/><br/>
          •	Regulatory Uncertainty: Cryptocurrency transactions may be subject to changing laws, regulations, or tax treatment at the local, state, or federal level. It is your sole responsibility to ensure compliance with applicable laws in your jurisdiction, and we make no representations as to the legal status of cryptocurrency in any jurisdiction.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Limitation of Liability
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          To the fullest extent permitted by law, QuickCoin, LLC and its officers, directors, employees, agents, and affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages, including but not limited to: 1) Losses arising from the volatility or price fluctuations of any cryptocurrency; 2) Technical malfunctions, interruptions, or delays in our services or third-party payment processing; 3) Unauthorized access, hacking, or other breaches of security, unless such breach is directly caused by our gross negligence; 4) Regulatory changes, legal developments, or changes in market conditions that may affect the legality or performance of cryptocurrency transactions.<br/><br/>
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Finality of Transactions
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          All transactions conducted through our platform are final and irreversible once confirmed. QuickCoin, LLC does not offer refunds or reversals for any transaction, whether due to User error, market conditions, or any other factor. It is the User’s sole responsibility to ensure that all transaction details, including wallet addresses and amounts, are accurate prior to confirming any transaction.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            User Obligations and Representations
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          By using our services, you represent and warrant that:<br/>
          •	You are at least 18 years of age and possess the legal authority to enter into these Terms;<br/>
          •	You will provide accurate, complete, and current information in connection with your account registration and use of our services;<br/>
          •	You will not engage in any unlawful or fraudulent activities in connection with your use of our services;<br/>
          •	You understand and agree that it is your responsibility to comply with all applicable laws, regulations, and tax obligations regarding the purchase, sale, and ownership of cryptocurrency.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Indemnification
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
            You agree to indemnify, defend, and hold harmless QuickCoin, LLC, its officers, directors, employees, and agents from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or related to your violation of these Terms, your use of the services, or your violation of any law or the rights of a third party.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Disclaimers of Warranties
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          QuickCoin, LLC provides its services on an "as is" and "as available" basis, without any warranties of any kind, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We make no representations or warranties regarding the accuracy, reliability, or completeness of any information provided through our services, nor do we guarantee the availability, functionality, or uninterrupted operation of our website.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Governing Law and Jurisdiction
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
            These Terms and any disputes arising from or related to these Terms or the use of our services shall be governed by and construed in accordance with the laws of the State of (state), without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the state and federal courts located in [County], [State], for the resolution of any disputes.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Arbitration Agreement
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
            Any disputes arising from or in connection with these Terms shall be resolved through binding arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules. The arbitration shall take place in [Location], and judgment on the arbitration award may be entered in any court having jurisdiction.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Amendments to Terms and Conditions
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          QuickCoin, LLC reserves the right to modify, amend, or update these Terms at any time. Any such changes will be posted on our website and will be effective upon posting. Your continued use of our services following the posting of any changes constitutes your acceptance of such changes.
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              pt: "24px",
              pb: "16px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "center",
            }}
          >
            Contact Information
          </Typography>
          <Typography tabIndex={-1}
            sx={{
              width: "100%",
              px: "32px",
              fontSize: "13px",
              color: theme.palette.mode === 'light' ? "#333" : '#909090',
              backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c',
              textAlign: "left",
            }}
          >
          For any inquiries or concerns regarding these Terms or our services, please contact us at team@quickcoin.ai.
          </Typography>
          </SimpleBar>
        </DialogContent>
        <DialogActions
          sx={{
            width: "95%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "85%",
              p:"8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap:"16px",
            }}
          >
            <Button
              sx={{
                width: "50%",
                backgroundColor: theme.palette.mode === 'light' ? "#f4f4f4" : '#2a2a2a',
                color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F',
                fontSize: "14px",
                fontWeight: "bold",
                borderRadius: 0,
                textTransform: "none",
                transition: "all 0.3s ease", // Smooth transition
                "&:hover": {
                  backgroundColor: theme.palette.mode === 'light' ? "#f8f8f8" : '#2c2c2c'
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleTermsClose}>
              Acknowledge
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
