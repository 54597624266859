import React, { useEffect, useState } from "react";
import {useTheme, Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import NumberTicker from "./magicui/NumberTicker";
import Divider from '@mui/material/Divider';
// import axios from "axios";

const mockTransactions = [
  { id: 1, cryptoType: 'BTC', paymentMethod: 'Debit Card', amount: '0.00553', time: '9 sec ago' },
  { id: 2, cryptoType: 'BTC', paymentMethod: 'Debit Card', amount: '0.00120', time: '1 min ago' },
  { id: 3, cryptoType: 'BTC', paymentMethod: 'Debit Card', amount: '0.00045', time: '3 min ago' },
  { id: 4, cryptoType: 'BTC', paymentMethod: 'Debit Card', amount: '0.00492', time: '2 min ago' },
  { id: 5, cryptoType: 'BTC', paymentMethod: 'Debit Card', amount: '0.000065', time: '4 min ago' },
];

// const getRelativeTime = (timestamp) => {
//   const now = new Date();
//   const orderTime = new Date(timestamp);
//   const diffInSeconds = Math.floor((now - orderTime) / 1000);

//   if (diffInSeconds < 60) return `${diffInSeconds} sec ago`;
//   const diffInMinutes = Math.floor(diffInSeconds / 60);
//   if (diffInMinutes < 60) return `${diffInMinutes} min ago`;
//   const diffInHours = Math.floor(diffInMinutes / 60);
//   return `${diffInHours} hr${diffInHours > 1 ? "s" : ""} ago`;
// };

// TransactionDetails component to display transaction list
const TransactionDetails = ({ transactions }) => {
  const theme = useTheme();
  return (
    <Box sx={{ mt: 2, padding: '16px' }}>
      {transactions.map((tx, index) => (
        <React.Fragment key={tx.id}>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 1.5 }}>
            <Box component="img"
                alt={tx.cryptoType}
                src={`/${tx.cryptoType}-logo.svg`}
                sx={{ width: 30, height: 30, mr: 2 }}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', fontWeight: 'bold' }}>
                {tx.amount} {tx.cryptoType}
              </Typography>
              <Typography variant="body2" sx={{ color: '#666' }}>
                via {tx.paymentMethod}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ color: '#666', whiteSpace: 'nowrap' }}>
              {tx.time}
            </Typography>
          </Box>
          {index < transactions.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
};

const TransactionsBox = () => {
  const [transactionCount, setTransactionCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    // const fetchTransactions = async () => {
    //   try {
    //     const response = await axios.get("https://app.quickcoin.ai/api/payment/recent-orders", {
    //       headers: {
    //       'Cache-Control': 'no-cache',
    //       },
    //     });
    //     const orders = response.data.orders.map((order) => ({
    //       ...order,
    //       time: getRelativeTime(order.time),
    //     }));
    //     setTransactionCount(orders.length);
    //     setTransactions(orders);
    //   } catch (error) {
    //     console.error("Error fetching recent orders:", error);
    //   }
    // };

    // fetchTransactions();
    // Replace this with API call to fetch the number of transactions in the past 24 hours
    setTransactionCount(mockTransactions.length+157);
    // Replace this with API call to fetch transaction details
    setTransactions(mockTransactions);
  }, []);

  return (
    <Box sx={{ px: 4 }}>
        <Typography variant="h6" sx={{ color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', fontWeight: 'bold' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: -4 }}>
            <Box sx={{ width: '8rem', height: '5rem', textAlign: 'right' }}>
                <NumberTicker
                  value={transactionCount}
                  direction="up"
                  delay={0.5}
                  style={{ fontSize: '5rem', color: theme.palette.mode === 'light' ? '#7c9388' : '#495E5F' }}
                />
            </Box>
            <Box sx={{ ml: 3, mt: 2, fontSize: {xs: '1.75rem', sm: '3rem'}}}>
                Transactions
            </Box>
            </Box>
            <Box sx={{ ml: 20, mt:2, fontSize: {xs: '0.9rem', sm: '1.5rem'}} }>
            in the last 24 hours
            </Box>
        </Typography>

        <TransactionDetails transactions={transactions.slice(0, 4)} />
    </Box>


  );
};

export default TransactionsBox;
