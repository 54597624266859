import React, { useState } from 'react';
import { useTheme, TextField, Box } from '@mui/material';

const VerificationCodeInput = ({ onCodeChange }) => {
  const [code, setCode] = useState(new Array(6).fill(''));
  const theme = useTheme();

  const handleChange = (value, index) => {
    if (!/^\d?$/.test(value)) return; // Only allow single digits
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    onCodeChange(newCode.join(''));

    // Automatically focus the next input field if available
    if (value && index < 5) {
      const nextField = document.getElementById(`digit-${index + 1}`);
      if (nextField) nextField.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
        const newCode = [...code];
        newCode[index] = ''; // Clear the value of the current field
        setCode(newCode);

        // Notify parent about code change
        onCodeChange(newCode.join(''));

        if (index > 0) {
          const prevField = document.getElementById(`digit-${index - 1}`);
          if (prevField) prevField.focus();
        }
      }
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text').slice(0, 6);
    if (/^\d{6}$/.test(pastedText)) {
      const newCode = pastedText.split('');
      setCode(newCode);

      onCodeChange(newCode.join(''));

      // Focus the last field
      const lastField = document.getElementById(`digit-5`);
      if (lastField) lastField.focus();

    }
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        width: {xs:"90%", sm: "75%", lg:"60%"},
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: {xs:1.2, sm: '3%', md: '4%'},
        mt: 2,
        px: 0.5,
      }}
      onPaste={handlePaste}
    >
      {code.map((digit, index) => (
        <TextField
          key={index}
          id={`digit-${index}`}
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          inputProps={{
            maxLength: 1,
            style: {
              height: '1.5rem',
              textAlign: 'center',
              fontSize: "1.5rem",
              color: theme.palette.mode === 'light' ? '#605b5b': '#f1f1f1',
            },
          }}
          sx={{
            width: {sm: "14.5%", lg:"60%"},
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '3px solid',
                borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                borderRadius: 3.5,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
              },
              '&.Mui-focused fieldset': {
                borderColor: '#7c9388', // Focus state color
              },
              '& input': {
                backgroundColor: 'transparent',
              },
              '& input:-webkit-autofill': {
                backgroundColor: 'transparent !important',
                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                transition: 'background-color 5000s ease-in-out 0s',
              },
              '& input:-internal-autofill-selected': {
                backgroundColor: 'transparent !important',
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
             },
            '& .MuiInputLabel-root.Mui-focused': {
               color: theme.palette.mode === 'light' ? '#7c9388' : '#4B6855',
            },
          }}
        />
      ))}
    </Box>
  );
};

export default VerificationCodeInput;
